import React from 'react';
import './App.css'; // Add a CSS file for content styling
import Navbar from './Components/Navbar';


function App() {
  return (
    <div>
      <Navbar />
      <div className="content">
        
        
      </div>
    </div>
  );
}

export default App;
