import React from "react";
import './UnderConstruction.css';

const Construction = () => {
  return (
    <div className="construction">
      <p className="text">This website is under construction</p>
    </div>
  );
};

export default Construction;
