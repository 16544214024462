import React from 'react';
import './Navbar.css';
import logo from '../Assets/logo1.1.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      {/* Logo Section */}
      <img
        src={logo}
        alt="Logo"
        className="navbar-logo"
      />

      {/* Navigation Buttons */}
      <div className="navbar-buttons">
        {/* Home Button */}
        <button className="navbar-button">
          <span className="navbar-button-label">Home</span>
        </button>

        {/* About Button with Dropdown */}
        <button className="navbar-button dropdown">
          <span className="navbar-button-label">About</span>
          <div className="dropdown-icon">
            <svg width="10" height="5" viewBox="0 0 10 5" fill="non">
              <path d="M0 0L5 5L10 0H0Z" />
            </svg>
          </div>
        </button>

        {/* Offering Button with Dropdown */}
        <button className="navbar-button dropdown">
          <span className="navbar-button-label">Offering</span>
          <div className="dropdown-icon">
            <svg width="10" height="5" viewBox="0 0 10 5" fill="non">
              <path d="M0 0L5 5L10 0H0Z" />
            </svg>
          </div>
        </button>

        {/* Portfolio Button with Dropdown */}
        <button className="navbar-button dropdown">
          <span className="navbar-button-label">Portfolio</span>
          <div className="dropdown-icon">
            <svg width="10" height="5" viewBox="0 0 10 5" fill="non">
              <path d="M0 0L5 5L10 0H0Z" />
            </svg>
          </div>
        </button>
      </div>

      {/* Get Started Button */}
      <button className="get-started-button">
        <span>Get Started</span>
        <svg
          className="arrow-icon"
          width="16"
          height="16"
          fill="white"
          viewBox="0 0 24 24"
        >
          <path d="M12 2L10.59 3.41L17.17 10H2V12H17.17L10.59 18.59L12 20L20 12L12 2Z" />
        </svg>
      </button>
    </nav>
  );
};

export default Navbar;
